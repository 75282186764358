import React from "react"

import { Helmet } from 'react-helmet'

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"

import img05 from "../../../src/images/portfolio/project-csr-05.gif"
import img07 from "../../../src/images/portfolio/project-csr-07.gif"

export const query = graphql`
    query {
        img01: file(relativePath: { eq: "portfolio/project-csr-01.png" }) {
            ...projectImg
        }
        img02: file(relativePath: { eq: "portfolio/project-csr-02.png" }) {
            ...projectImg
        }
        img03: file(relativePath: { eq: "portfolio/project-csr-03.png" }) {
            ...projectImg
        }
        img04: file(relativePath: { eq: "portfolio/project-csr-04.jpg" }) {
            ...projectImg
        }
        img06: file(relativePath: { eq: "portfolio/project-csr-06.png" }) {
            ...projectImg
        }
        img08: file(relativePath: { eq: "portfolio/project-csr-08.png" }) {
            ...projectImg
        }
    }
`
const ProjectCsrPage = ({ data }) => (
  <>
    <Seo title="CSR Connect project - Scott Luxford" />
    <Helmet>
      <body className="projects projects-theme-csr" />
    </Helmet>
    <Header />
    <main className="project" id="top">
      <article>

        <Link className="project-crumb" to="/projects"><span className="sr-only">Back to</span><span role="presentation">&lt;</span> Projects</Link>
        <h1 className="heading-offset">CSR Connect</h1>

        <ul>
          <li><strong>Role</strong>
            Senior UI/UX Designer &amp; Frontend Developer</li>

          <li><strong>Responsibilities</strong>
            User research, wireframes, concepts, prototypes, product design, AngularJS development, HTML, CSS, JavaScript, Growth Hacking</li>

          <li><strong>Company</strong>
            CSR Limited</li>

          <li><strong>When</strong>
            2014 - 2019</li>
        </ul>

        <section className="section section-text-first">

          <div className="section-text">
            <h2>Background</h2>

            <p>I was engaged to work on CSR's Digital transformation project, which consisted primarily of driving growth on their B2B customer portal, CSR Connect.</p>

            <p>CSR is a bit of a household name - for sugar - but CSR now focuses on building products, their line up consisting of some of Australia's most well known brands.</p>

            <p>During my time at CSR, the team was able to grow the customer base from ~200 users to over 7000 unique users.</p>
          </div>

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img01)} alt="CSR logo with caption reading 'Not sugar anymore!'" />
          </figure>

        </section>

        <section className="section section-media-first">

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img02)} alt="Old CSR Connect logo transitioning to a new, modern version" />
          </figure>

          <div className="section-text">
            <h2>Starting again</h2>

            <p>Our remit was to follow a Lean startup methodology, with emphasis on user centred design principles in order to grow the product.</p>

            <p>Modernising the product was also a means to prepare it for design thinking and agile iteration, breaking it down and rebuilding the design language.</p>

            <p>After speaking with customers and stakeholders, we decided the brand had to stay. But, before I could start work I HAD to flatten the dated 3D logo - not just for trend's sake, but to ensure Connect looked and felt like other A-class apps and websites our customers encountered.</p>
          </div>

        </section>

        <section className="section section-solo">

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img03)} alt="Sketched text depicting the Lean UX principle of working product iteration over traditional documented deliverables." />
          </figure>

        </section>

        <section className="section section-media-first">

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img04)} alt="A notepad sketch depicting thumbnails of a workflow." />
          </figure>

          <div className="section-text">
            <h2>Lean UX</h2>

            <p>My previous role was at a big 4 bank, and I was keen to see the end of long CX documents with thousands of revisions. So in keeping with Lean startup, we had to adopt lean UX, and conduct most of our user research on the fly through rapid prototypes and feedback sessions.</p>

            <p>In the early days of the project my process often went from sketchbook straight into build to get the product value, as researched by the business stakeholders, into customer hands as quickly as possible.</p>

            <p>Gradually, we incorporated more user research into our process in order to refine the product, conducting journey mapping and building base personas to help write new, customer-centred user stories.</p>
          </div>

        </section>
        <section className="section section-text-first">

          <div className="section-text">
            <h2>Customer research methods</h2>

            <p>Prototypes were our most powerful tool in validating our solutions. We found that higher fidelity prototypes conveyed our solutions best, as our building trade-based customers related to products and prices they were familiar with - lofi wireframes/prototypes often caused distraction: "it could use some more colour" or "what's lorem ipsum?".</p>

            <p>We were able to achieve hifi prototypes via Invision with relative ease, but due to CSR's brands and product lines, we could generally only target a single customer segment at a time.</p>
          </div>

          <figure className="section-media" style={{maxHeight: '450px'}}>
            <img src={img05} alt="Animation depicting an interactive prototype, where a user adds a product to a favourites list.'" />
          </figure>

        </section>

        <section className="section">

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img06)} alt="User interface design for a simplified application home menu, task based links and simple navigation." />
          </figure>

          <div className="section-text">
            <h2>Growth vs Traditional Customer Service</h2>

            <p>All new digital products that compete with traditional phone, email and face-to-face interactions experience friction when introduced. We found that a large percentage of our customers actually went out of their way to go to a store to pay their bills and say hi to the staff, rather than pay online in 3 clicks.</p>

            <p>Likewise, many customers prefer ordering over the phone for the direct interaction and confidence of speaking to a real person.</p>

            <p>Connect's edge was being able to offer all of these services in one place 24/7, but we discovered through analytics and customer interviews that many customers were not aware of the full feature set Connect afforded them. We hypothesised that if the services were presented more simply, that they would be more likely to be used.</p>

            <p>So we took a simple approach to exposing these services as tasks - raising awareness to the services available and making them easier to access. This ‘Launchpad' (above) replaced our previously crowded dashboard: a snowball-collection of customer-centered widget that as a whole, present a screen full of data that we found overwhelemed all but our most pedantic customers.</p>
          </div>
        </section>

        <section className="section section-media-first">

          <figure className="section-media">
            <img src={img07} alt="Animation depicting an in-app card game experience, with a card flipping to reveal a message to the user." />
          </figure>

          <div className="section-text">
            <h2>Incentive to go online</h2>

            <p>Three years running, Connect has run a customer incentive experience called ‘Connect &amp; Win'. It started off as an idea for a gamified egg hunt, but we workshopped it into a gamified, interactive guide to the features of Connect. Thanks to great comms, users old and new logged into the app to play the game - all the while learning about new features, and supplying feedback as they went.</p>

            <p>To ensure the game was a delightful experience for our active users, we had to make sure that the game had a fun and playful dynamic, so each year it was my task to design and build a new version of the game.</p>

            <p>Connect and Win was always a great opportunity to design outside of our standard palette and try something new - implementing animation and audio to progressively enhance the experience and set it apart from the business-as-usual functions of the application.</p>
          </div>

        </section>

        <section className="section section-solo">

          <figure className="section-media">
            <GatsbyImage image={getImage(data.img08)} alt="Assets from the gamified Connect and Win campaign: Showing a slice of birthday cake transitioning to a empty plate of crumbs upon completion of the competition." />
          </figure>

        </section>

        <section className="section section-solo">

          <div className="section-text">
            <h2>Stats don't lie</h2>
            <p>I noted down some achievements that I am proud to say were attributed to my work, knowing that one day I might get to share them:</p>

            <ul>
              <li><strong>645% improvement</strong>
                User referral up through simplifying the language to be more customer-centric.</li>

              <li><strong>Doubled* conversions!</strong>
                Converting users through Growth Hacking and split testing content changes. *94.3% more users</li>

              <li><strong>Excellent NPS of 50+</strong>
                Team effort: we worked hard to ensure users loved our app</li>
            </ul>
          </div>

        </section>

      </article>

      <p className="back-links"><a href="#top">Back to top</a> <span role="presentation">/</span> <Link to="/projects">Back to projects</Link></p>
    </main>
    <Footer />
  </>
)

export default ProjectCsrPage
